import { useNavigate, useParams } from "react-router-dom";
import { Asset, selectAssetUsage, setStatus } from "../features/assetUsage/assetUsageSlice";
import { useSave } from "./useSave";
import { useFormControl } from "./useFormControl";
import { useCalculate } from "./useCalculate";
import { FormStatus } from "../models/formStatus";
import { useDispatch, useSelector } from "react-redux";
import { useStatus } from "./useStatus";
import { EmissionsCategoryId } from "./useEmissionsCategory";

type EmissionsEntryDto = {
  highLevelDetailsId: number;
  emissionsSourceId: number;
  quantity: number;
  percentageRecycled: number | undefined;
  scopeId: number;
};

type SaveEmissionsEntryRequest = {
  emissionsEntries: EmissionsEntryDto[];
};

const createSavePayload = (assets: Asset[], workId: string): SaveEmissionsEntryRequest => {
  return {
    emissionsEntries: assets.map((a) => ({
      highLevelDetailsId: parseInt(workId),
      emissionsSourceId: a.assetId || 0,
      quantity: parseInt(a.quantity || ""),
      unitOfMeasureId: a.unitOfMeasureId,
      percentageRecycled: parseFloat(a.percentageRecycled || ""),
      scopeId: parseInt(a.scopeId || ""),
    })),
  };
};

export const useEmissionsEntry = () => {
  const assetUsage = useSelector(selectAssetUsage);
  const { closeEntry } = useFormControl();
  const { save, isSaving } = useSave<SaveEmissionsEntryRequest>();
  const navigate = useNavigate();
  const { mode, workId } = useParams();
  const { calculateResults } = useCalculate();
  const { canSaveOrEdit } = useStatus(workId ?? "");
  const dispatch = useDispatch();

  const back = () => {
    navigate(`/${mode}/${workId}/material-usage`);
  };

  const saveAndClose = async () => {
    if (!workId || !canSaveOrEdit) return;

    const { success } = await save(
      createSavePayload(assetUsage.form, workId),
      `/emissionsEntry/save/emissionscategory/${EmissionsCategoryId.Asset}`
    );

    await calculateResults(workId);

    if (success) {
      closeEntry();
    }
  };

  const next = async () => {
    if (!workId) return;
    if (!canSaveOrEdit) {
      navigate(`/${mode}/${workId}/waste`);
      return;
    }

    const { success } = await save(
      createSavePayload(assetUsage.form, workId),
      `/emissionsEntry/save/emissionscategory/${EmissionsCategoryId.Asset}`
    );

    if (success) {
      navigate(`/${mode}/${workId}/waste`);
      dispatch(setStatus(FormStatus.Complete));
    }
  };

  return {
    back,
    saveAndClose,
    next,
    isSaving,
    canSaveOrEdit,
  };
};
