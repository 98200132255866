import "./AssetRow.scss";
import { Select, NumberInput } from "../../FormComponents";
import { DeleteButton } from "../../DeleteButton/DeleteButton";
import { Asset } from "../../../features/assetUsage/assetUsageSlice";
import { useEmissionsTypeLookup } from "../../../hooks/useEmissionsType";
import { useEmissionsSourceLookup } from "../../../hooks/useEmissionsSource";
import { useScope } from "../../../hooks/useScope";
import { Category } from "../../../utils/consts";
import { useUnitOfMeasureLookup } from "../../../hooks/useUnitOfMeasure";

interface AssetRowProps {
  asset: Asset;
  onRemove: (assetId: number | null) => void;
  onChange: (a: Asset) => void;
  disabled?: boolean;
}

export const AssetRow = ({ asset, onRemove, onChange, disabled }: AssetRowProps) => {
  const { id, assetTypeId, assetId, quantity, percentageRecycled, unitOfMeasureId, scopeId } =
    asset;
  const { values: assetTypes, isLoading: isAssetTypesLoading } = useEmissionsTypeLookup(
    Category.ASSET
  );
  const { values: assets, isLoading: isAssetsLoading } = useEmissionsSourceLookup(
    Category.ASSET,
    assetTypeId ? assetTypeId : 0
  );
  const { values: scopes } = useScope();
  const { values: units, isLoading: isUnitsLoading } = useUnitOfMeasureLookup(assetId ?? 0);

  const handleAssetTypeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...asset, assetTypeId: Number(e.currentTarget.value), assetId: null });
  };

  const handleAssetChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...asset, assetId: Number(e.currentTarget.value) });
  };

  const handleQuantityChange = (quantity: string) => {
    return onChange({ ...asset, quantity: quantity });
  };

  const handleUnitofMeasureChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...asset, unitOfMeasureId: Number(e.currentTarget.value) });
  };

  const handlePercentageRecycledChange = (percentageRecycled: string) => {
    return onChange({ ...asset, percentageRecycled: percentageRecycled });
  };

  const handleScopeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...asset, scopeId: e.currentTarget.value });
  };

  return (
    <div className="asset-row">
      <Select
        id={`asset-type-${id}`}
        label="Asset Type"
        options={assetTypes}
        value={assetTypeId}
        onChange={handleAssetTypeChange}
        disabled={disabled}
        isLoading={isAssetTypesLoading}
      />
      <Select
        id={`asset-${id}`}
        label="Asset"
        options={assets}
        value={assetId}
        onChange={handleAssetChange}
        disabled={disabled}
        isLoading={isAssetsLoading}
      />
      <NumberInput
        id={`quantity-${id}`}
        label="Quantity"
        value={quantity}
        min={0}
        onChange={handleQuantityChange}
        disabled={disabled}
      />
      <Select
        id={`units-${id}`}
        label="Units"
        options={units}
        value={unitOfMeasureId}
        onChange={handleUnitofMeasureChange}
        disabled={disabled}
        isLoading={isUnitsLoading}
      />
      <NumberInput
        id={`percentage-recycled-${id}`}
        label="Recycled"
        value={percentageRecycled}
        min={0}
        max={100}
        onChange={handlePercentageRecycledChange}
        disabled={disabled}
        percentage={true}
      />
      <Select
        id={`scope-${id}`}
        label="Scope"
        options={scopes}
        value={scopeId}
        onChange={handleScopeChange}
        disabled={disabled}
      />
      <DeleteButton onClick={() => onRemove(id)} disabled={disabled} />
    </div>
  );
};
