import { TextInput } from "../FormComponents";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import Logo from "../../assets/images/logo.png";
import "./Login.scss";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";

export const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");

  const { login, isLoading, verifyEmail, verifyEmailAddress } = useAuth();

  return (
    <div className="login">
      <div className="login__sidebar">
        <div className="login__sidebar__logo">
          <img src={Logo} alt="Road To Net Zero Logo" />
        </div>
        <div className="login__sidebar__form">
          {verifyEmail ? (
            <div className="login__sidebar__verify">
              <h1>Confirm your email</h1>
              <div>
                A confirmation email has been sent to {email}. Copy the
                verification code from the email into the box below and click on
                the Verify Code button to activate your account.
              </div>
              <TextInput
                id="verification"
                label="Verification code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.currentTarget.value)}
                disabled={isLoading}
              />
              <PrimaryButton
                label="Verify Code"
                onClick={() => verifyEmailAddress(email, verificationCode)}
                disabled={isLoading}
              />
              <div>
                Click the button below after 5 minutes if you didn't get the
                email.
              </div>
              <PrimaryButton
                label="Re-send Confirmation Email"
                onClick={() =>
                  login(email, password, "Sending verification email...")
                }
                disabled={isLoading}
              />
            </div>
          ) : (
            <>
              <TextInput
                id="username"
                label="Username"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                disabled={isLoading}
              />
              <TextInput
                id="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                type="password"
                disabled={isLoading}
              />
              <PrimaryButton
                label="Log In"
                onClick={() => login(email, password, "Logging in...")}
                disabled={isLoading}
              />
            </>
          )}
        </div>
        <div className="login__sidebar__bottom">
          For access or more information, please contact:
          <b>
            <a
              className="login__sidebar__mail-link"
              href="mailto: roadtonetzero@eatechnology.com"
            >
              roadtonetzero@eatechnology.com
            </a>
          </b>
        </div>
      </div>
    </div>
  );
};
