import { ToggleableDoughnutCard } from "../../components/Dashboard/ToggleableDoughnutCard/ToggleableDoughnutCard";
import { StatList } from "../../components/Dashboard/StatList/StatList";
import "./OrganisationResultsDashboard.scss";
import { MetricCard } from "../../components/Dashboard/MetricCard/MetricCard";
import { HeaderAction } from "../../components/HeaderAction/HeaderAction";
import { useDispatch } from "react-redux";
import {
  setStartDate,
  setEndDate,
} from "../../features/organisationResultsSlice/organisationResultsSlice";
import { SectorResultsPageContainer } from "../../components/SectorResultsPageContainer/SectorResultsPageContainer";
import { shortenResultsDataTotal } from "../../utils/dataUtils";
import { DateRange } from "../../components/FormComponents/Date/Date";
import { useOrganisationResults } from "../../hooks/useOrganisationResults";

export const OrganisationResultsDashboard = () => {
  const dispatch = useDispatch();

  const { data: results, isFetching } = useOrganisationResults();

  return (
    <SectorResultsPageContainer
      heading={"Emissions for My Organisation"}
      actions={
        <>
          <DateRange
            id={""}
            onChange={({ startDate, endDate }) => {
              dispatch(setStartDate(startDate));
              dispatch(setEndDate(endDate));
            }}
            dateFieldWrapper={HeaderAction}
            displayHorizontally
            startDateValue={""}
            endDateValue={""}
          />
        </>
      }
    >
      <div className="organisation-dashboard">
        <div className="organisation-dashboard__row">
          <div className="organisation-dashboard__row__single">
            <div className="organisation-dashboard__row__single">
              <MetricCard metrics={results?.highlightedMetrics} isLoading={isFetching} />
            </div>
          </div>
          <div className="organisation-dashboard__row__double">
            <ToggleableDoughnutCard
              data={results?.chartableData}
              titlePlaceholder="emissions breakdown"
              isLoading={isFetching}
              totalModifier={shortenResultsDataTotal}
              subtitle={
                results?.respondents ? `${results.respondents.toString()} Entries` : "0 Entries"
              }
            />
          </div>
        </div>
        <div className="organisation-dashboard__row__triple">
          <StatList
            title={"top 5 emissions"}
            stats={results?.topFiveEmissions}
            isLoading={isFetching}
          />
        </div>
      </div>
    </SectorResultsPageContainer>
  );
};
