import "./Checkbox.scss";
import Check from "../../../assets/icons/check.svg";

interface CheckboxProps {
  id: string;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const Checkbox = ({ id, checked, onChange }: CheckboxProps) => {
  return (
    <span className="checkbox">
      <label className={`checkbox__label ${checked && "checkbox__label--checked"}`} htmlFor={id}>
        <img src={Check} alt="" />
      </label>
      <input id={id} type="checkbox" checked={checked} onChange={onChange} />
    </span>
  );
};
