import { useDispatch, useSelector } from "react-redux";
import { FieldGroup } from "../../components/FieldGroup/FieldGroup";
import WasteHeaders from "../../components/FieldHeaders/WasteHeaders/WasteHeaders";
import { WasteRow } from "../../components/FieldRows/WasteRow/WasteRow";
import { FormButtons } from "../../components/FormButtons/FormButtons";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import { addWaste, removeWaste, selectWaste, updateWaste } from "../../features/waste/wasteSlice";
import { useWasteEmissionsEntry } from "../../hooks/useWasteEmissionsEntry";
import { useActiveOrganisation } from "../../hooks/useActiveOrganisation";

export const Waste = () => {
  const { form } = useSelector(selectWaste);
  const { back, saveAndClose, next, isSaving, canSaveOrEdit } = useWasteEmissionsEntry();
  const dispatch = useDispatch();
  const { activeOrganisation } = useActiveOrganisation();
  const disableControls = isSaving || canSaveOrEdit === false;

  const infoPaneText = (
    <>
      Please include the waste generated in your road/street works this quarter. Where the type of
      waste is unknown, average construction should be used. <br />
      <br />
      If known, please include the percentage to be recycled. <br />
      <br />
      For Scope, please add whether this is from in-house usage or from contractor usage. If
      unknown, or a combination of the two, please use <i>both</i> <br />
      <br />
      At any point, you can <i>Save and Close</i>. You will be able to return to the same point
      using the Entry Dashboard. <br />
      <br />
      Note, if any significant sources of emissions are missing from this section, please contact
      the team at <a href="mailto: roadtonetzero@eatechnology.com">RTNZ Email</a> <br />
      <br />
    </>
  );

  return (
    <PageContainer
      heading={`Waste | ${activeOrganisation?.name}`}
      informationalPaneBodyText={infoPaneText}
      displayNavigationPane
    >
      <FieldGroup>
        <WasteHeaders />
        {form.map((waste) => (
          <WasteRow
            waste={waste}
            key={waste.id}
            onRemove={() => dispatch(removeWaste(waste))}
            onChange={(updatedWaste) => dispatch(updateWaste(updatedWaste))}
            disabled={disableControls}
          />
        ))}
        <PrimaryButton
          onClick={() => dispatch(addWaste())}
          label="Add New Waste"
          disabled={disableControls}
        />
      </FieldGroup>

      <FormButtons
        onBack={back}
        onSaveAndClose={canSaveOrEdit ? saveAndClose : undefined}
        onSubmit={next}
        disabled={isSaving}
        submitLabel={canSaveOrEdit ? "Save and View Results" : "View Results"}
      />
    </PageContainer>
  );
};
