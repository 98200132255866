import { Lookup } from "../../../models/lookup";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";
import "./Select.scss";

interface SelectProps {
  id: string;
  label: string;
  value: string | number | null;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  options: Lookup[];
  disabled?: boolean;
  useNoneOptionDisabled?: boolean;
  isLoading?: boolean;
}

export const Select = ({
  id,
  label,
  value,
  onChange,
  options,
  disabled = false,
  useNoneOptionDisabled,
  isLoading,
}: SelectProps) => {
  const hasValue = () => value != null;

  return (
    <div className="select">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <label
            className={`select__label ${hasValue() ? "select__label--filled" : ""}`}
            htmlFor={id}
          >
            {label}
          </label>
          <select id={id} onChange={onChange} value={value || undefined} disabled={disabled}>
            {!useNoneOptionDisabled && <option style={{ display: "none" }} />}
            {options?.map((o) => (
              <option key={o.name} value={o.id}>
                {o.name}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
};
