import { useNavigate, useSearchParams } from "react-router-dom";
import { env } from "../env";
import { HttpMethod } from "../models/httpMethod";
import { HttpStatusCode } from "../models/httpStatusCode";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { clearToken, getAuthHeader, setToken } from "../utils/tokenUtils";
import { clearUser, selectUser } from "../features/user/userSlice";
import { useSelector } from "react-redux";

type LoginRequest = {
  email: string;
  password: string;
};

type LoginResponse = {
  token: string;
  verifyEmail: boolean;
};

type ChangePasswordRequest = {
  currentPassword: string;
  newPassword: string;
};

type EmailVerificationRequest = {
  email: string;
  code: string;
};

type EmailVerificationResponse = {
  token: string;
};

export const useAuth = () => {
  const { user } = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verifyEmail, setVerifyEmail] = useState<boolean>(false);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const isContractor = user.role === "Contractor";

  const login = async (email: string, password: string, msg: string) => {
    const loadingToast = toast.loading(msg);
    setIsLoading(true);

    const payload: LoginRequest = {
      email,
      password,
    };

    try {
      const response = await fetch(
        `${env.REACT_APP_API_BASE_URL}/authentication/login`,
        {
          method: HttpMethod.POST,
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const success = response.status === HttpStatusCode.Ok;

      if (!success) {
        setIsLoading(false);
        toast.error("Invalid login details.");
        return;
      }

      const body = (await response.json()) as LoginResponse;

      if (body.verifyEmail) {
        setIsLoading(false);
        setVerifyEmail(true);
        return;
      }

      localStorage.setItem("token", body.token);
      setVerifyEmail(false);
      setToken(body.token);

      const redirectUrl = params.get("redirect");
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/");
      }
    } catch (e) {
      toast.error("Something went wrong while logging in.");
    } finally {
      toast.dismiss(loadingToast);
      setIsLoading(false);
    }
  };

  const logout = useCallback(() => {
    clearToken();
    clearUser();
    localStorage.removeItem("token");
    navigate("/login");
  }, [navigate]);

  const changePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    const loadingToast = toast.loading("Changing password...");
    setIsLoading(true);

    const payload: ChangePasswordRequest = {
      currentPassword,
      newPassword,
    };
    try {
      const response = await fetch(
        `${env.REACT_APP_API_BASE_URL}/authentication/changepassword`,
        {
          method: HttpMethod.POST,
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthHeader(),
          },
        }
      );

      const success = response.status === HttpStatusCode.Ok;

      if (!success) {
        setIsLoading(false);
        toast.error("An error occurred");
        return;
      }

      toast.success("Password changed!");
      navigate(`/`);
    } catch (e) {
      toast.error("An error occurred");
    } finally {
      toast.dismiss(loadingToast);
      setIsLoading(false);
    }
  };

  const changeActiveOrganisation = async (organisationId: number) => {
    const loadingToast = toast.loading("Selecting active organisation...");
    setIsLoading(true);

    try {
      const response = await fetch(
        `${env.REACT_APP_API_BASE_URL}/authentication/changeactiveorganisation`,
        {
          method: HttpMethod.POST,
          body: JSON.stringify(organisationId),
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthHeader(),
          },
        }
      );

      const success = response.status === HttpStatusCode.Ok;

      if (!success) {
        setIsLoading(false);
        toast.error("An error occurred");
        return;
      }

      const jwt = await response.text();
      localStorage.setItem("token", jwt);

      setToken(jwt);
      toast.success("Active Organisation selected!");
      navigate(`/`);
    } catch (e) {
      toast.error("An error occurred");
    } finally {
      toast.dismiss(loadingToast);
      setIsLoading(false);
    }
  };

  const verifyEmailAddress = async (email: string, code: string) => {
    const payload: EmailVerificationRequest = {
      email,
      code,
    };

    const response = await fetch(
      `${env.REACT_APP_API_BASE_URL}/authentication/verifyEmail`,
      {
        method: HttpMethod.POST,
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const success = response.status === HttpStatusCode.Ok;

    if (!success) {
      toast.error("Invalid verification code");
      return;
    }

    toast.success("Successfully verified");

    const body = (await response.json()) as EmailVerificationResponse;

    localStorage.setItem("token", body.token);

    setVerifyEmail(false);
    setToken(body.token);

    const redirectUrl = params.get("redirect");
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate("/");
    }
  };

  return {
    login,
    logout,
    changePassword,
    changeActiveOrganisation,
    verifyEmail,
    isLoading,
    isContractor,
    verifyEmailAddress,
  };
};
