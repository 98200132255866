import { useState } from "react";
import { Select } from "../../components/FormComponents";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import { useAuth } from "../../hooks/useAuth";
import { InputRow } from "../../components/InputRow/InputRow";
import { selectUser } from "../../features/user/userSlice";
import { useSelector } from "react-redux";
import { useActiveOrganisation } from "../../hooks/useActiveOrganisation";

const ChangeOrganisation = () => {
  const { changeActiveOrganisation, isLoading } = useAuth();
  const { availableOrganisations, isAvailableOrganisationsLoading } = useActiveOrganisation();
  const { user } = useSelector(selectUser);
  const [organisationId, setOrganisationId] = useState<number>(user.activeOrganisationId);

  return (
    <PageContainer heading="Change Organisation">
      <div className="active-organisation">
        <InputRow inputId="active-organisation" label="Active Organisation">
          <Select
            id={"change-active-organisation"}
            label={"Active Organisation"}
            value={organisationId}
            isLoading={isAvailableOrganisationsLoading}
            options={availableOrganisations ?? []}
            onChange={(e) => setOrganisationId(Number(e.currentTarget.value))}
          />
        </InputRow>
        <PrimaryButton
          label="Update Organisation"
          onClick={() => changeActiveOrganisation(organisationId)}
          disabled={isLoading}
        />
      </div>
    </PageContainer>
  );
};

export default ChangeOrganisation;
