import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { getToken, parseJwt } from "../../utils/tokenUtils";

type User = {
  role: string;
  activeOrganisationId: number;
};
type UserState = {
  user: User;
};

const getRole = () => {
  const jwt = getToken();

  if (!jwt) return "Viewer";

  const { role } = parseJwt(jwt);
  return role;
};

const getActiveOrganisationId = () => {
  const jwt = getToken();

  if (!jwt) return 0;

  const { activeOrganisationId } = parseJwt(jwt);

  return activeOrganisationId;
};

const initialState: UserState = {
  user: { role: getRole(), activeOrganisationId: getActiveOrganisationId() },
};

export const userReducer = {
  setRole: (state: UserState, action: PayloadAction<string>) => {
    state.user.role = action.payload;
  },
  setActiveOrganisationId: (state: UserState, action: PayloadAction<number>) => {
    state.user.activeOrganisationId = action.payload;
  },
  clearUser: (state: UserState) => {
    state = initialState;
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: userReducer,
});

export const { setRole, setActiveOrganisationId, clearUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
