import { Link } from "react-router-dom";
import { EntriesTable } from "../../components/EntriesTable/EntriesTable";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { useHighLevelDetails } from "../../hooks/useHighLevelDetails";
import { StatusName } from "../../hooks/useStatus";
import "./EntryDashboard.scss";
import { usePairedOrganisation } from "../../hooks/usePairedOrganisation";

export const EntryDashboard = () => {
  const {
    data: highLevelDetails,
    isLoading,
    deleteHighLevelDetail,
    actionableHighLevelDetails,
    isLoadingActionableHighLevelDetails,
  } = useHighLevelDetails();

  const { pairedOrganisations } = usePairedOrganisation();

  const approvedHighLevelDetails = highLevelDetails?.filter(
    (detail) => detail.status.name === StatusName.APPROVED
  );

  const draftHighLevelDetails = highLevelDetails?.filter(
    (detail) =>
      detail.status.name === StatusName.DRAFT &&
      detail.organisation.id === detail.createdByOrganisation.id
  );

  return (
    <PageContainer
      heading="Entry Dashboard"
      informationalPaneBodyText="On this page, you can find all your company’s entries on the carbon calculator."
    >
      {pairedOrganisations?.length ? (
        isLoadingActionableHighLevelDetails ? (
          <div>It's loading!</div>
        ) : (
          <EntriesTable
            title="Pending Contractor Entries"
            highLevelDetails={actionableHighLevelDetails}
            actions={(highLevelDetail) => (
              <div className="actions">
                <Link
                  aria-label="Review Entry"
                  className="actions__link"
                  to={`/review/${highLevelDetail.id}/basic-details`}
                >
                  Review
                </Link>
              </div>
            )}
          />
        )
      ) : null}
      {isLoading ? (
        <div>It's loading!</div>
      ) : (
        <>
          <EntriesTable
            title="My Draft entries"
            highLevelDetails={draftHighLevelDetails}
            deleteHighLevelDetail={deleteHighLevelDetail}
            actions={(highLevelDetail) => (
              <Link
                aria-label="Edit Entry"
                className="actions__link"
                to={`/edit/${highLevelDetail.id}/basic-details`}
              >
                Edit
              </Link>
            )}
          />
          <EntriesTable
            title="All Approved Entries"
            highLevelDetails={approvedHighLevelDetails}
            deleteHighLevelDetail={deleteHighLevelDetail}
          />
        </>
      )}
    </PageContainer>
  );
};
