import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export interface OrganisationResultFormState {
  startDate: string;
  endDate: string;
}

const initialState: OrganisationResultFormState = {
  startDate: "",
  endDate: "",
};

const OrganisationResultFormReducer = {
  setStartDate: (state: OrganisationResultFormState, action: PayloadAction<string>) => {
    state.startDate = action.payload;
  },
  setEndDate: (state: OrganisationResultFormState, action: PayloadAction<string>) => {
    state.endDate = action.payload;
  },
};

export const OrganisationResultFormSlice = createSlice({
  name: "organisationResultForm",
  initialState: initialState,
  reducers: OrganisationResultFormReducer,
});

export const { setEndDate, setStartDate } = OrganisationResultFormSlice.actions;

export const selectOrganisationResultForm = (state: RootState) => state.organisationResultForm;
