import Header from "../Header/Header";
import "./EnergyConsumerHeaders.scss";

const EnergyConsumerHeaders = () => {
  return (
    <div className="energy-consumer-headers">
      <Header label="Energy type" />
      <Header label="Purpose" />
      <Header label="Quantity" />
      <Header label="Units" />
      <Header label="Scope" />
    </div>
  );
};

export default EnergyConsumerHeaders;
