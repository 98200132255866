import { FieldGroup } from "../../components/FieldGroup/FieldGroup";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { Select, TextInput } from "../../components/FormComponents";
import { InputRow } from "../../components/InputRow/InputRow";
import {
  selectBasicDetails,
  setDescription,
  setEndDate,
  setStartDate,
  setTemplateId,
} from "../../features/basicDetails/basicDetailsSlice";
import { FormButtons } from "../../components/FormButtons/FormButtons";
import { useBasicDetails } from "../../hooks/useBasicDetails";
import { useDispatch, useSelector } from "react-redux";
import { DateRange } from "../../components/FormComponents/Date/Date";
import { EntryMode } from "../../models/entryMode";
import { useHighLevelDetails } from "../../hooks/useHighLevelDetails";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { useAuth } from "../../hooks/useAuth";
import { useActiveOrganisation } from "../../hooks/useActiveOrganisation";

export const NewWorks = () => {
  const { form } = useSelector(selectBasicDetails);
  const { saveAndClose, back, next, isSaving, isRestored, mode, workId, canSaveOrEdit } =
    useBasicDetails();
  const {
    data: highLevelDetails,
    activeOrganisationData: parentHighLevelDetails,
    isLoading,
  } = useHighLevelDetails();
  const { isContractor } = useAuth();
  const dispatch = useDispatch();
  const { activeOrganisation } = useActiveOrganisation();
  const disableControls = isSaving || canSaveOrEdit === false;

  const formattedHighLevelDetails = [
    {
      id: 0,
      name: `No template ${highLevelDetails ? "selected" : "available, previous entries required"}`,
    },
  ];

  const templateList = isContractor ? parentHighLevelDetails : highLevelDetails;

  templateList?.map((x) =>
    formattedHighLevelDetails.push({
      id: Number(x.id),
      name: `${x.startDate} -> ${x.endDate} | ${x.description}`,
    })
  );

  const infoPaneText = (
    <>
      The description field is a free-text box for any information that may be useful for you to
      make the entry more identifiable (i.e. South West Region, ACME Corp data etc.)
    </>
  );

  return (
    <PageContainer
      heading={`Basic Details | ${activeOrganisation?.name}`}
      informationalPaneBodyText={infoPaneText}
      displayNavigationPane
    >
      {mode !== EntryMode.Edit || isRestored === true ? (
        <FieldGroup>
          <h2>Date range and description</h2>
          <DateRange
            id={"new-works-date-range"}
            onChange={({ startDate, endDate }) => {
              dispatch(setStartDate(startDate));
              dispatch(setEndDate(endDate));
            }}
            dateFieldWrapper={InputRow}
            startDateValue={form.startDate}
            endDateValue={form.endDate}
            disabled={disableControls}
          />
          <InputRow inputId="description" label="Description">
            <TextInput
              id="description"
              label="Description"
              value={form.description}
              onChange={(e) => dispatch(setDescription(e.currentTarget.value))}
              disabled={disableControls}
            />
          </InputRow>
          {!workId && (
            <>
              <h2>Would you like to use a previous entry as a template?</h2>

              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <InputRow inputId="template" label="Template">
                  <Select
                    id={"template"}
                    label={"Select Template"}
                    value={form.templateId}
                    onChange={(e) => dispatch(setTemplateId(Number(e.currentTarget.value)))}
                    options={formattedHighLevelDetails}
                    useNoneOptionDisabled={true}
                  />
                </InputRow>
              )}
            </>
          )}
        </FieldGroup>
      ) : null}

      <FormButtons
        onBack={back}
        onSaveAndClose={canSaveOrEdit ? saveAndClose : undefined}
        onNext={next}
        disabled={isSaving}
      />
    </PageContainer>
  );
};
