import { useNavigate, useParams } from "react-router-dom";
import {
  BasicDetails,
  selectBasicDetails,
  setStatus,
} from "../features/basicDetails/basicDetailsSlice";
import { useSave } from "./useSave";
import { useFormControl } from "./useFormControl";
import { HttpMethod } from "../models/httpMethod";
import { FormStatus } from "../models/formStatus";
import { useDispatch, useSelector } from "react-redux";
import { EntryMode } from "../models/entryMode";
import toast from "react-hot-toast";
import { useStatus } from "./useStatus";

type SaveBasicDetailsRequest = {
  highLevelDetails: {
    id: number | undefined;
    description: string;
    startDate: string;
    endDate: string;
  };
  highLevelDetailsTemplateId?: number;
};

type SaveBasicDetailsResponse = {
  id: number;
  startDate: string;
  endDate: string;
  description: string;
};

const createSavePayload = (
  workId: string | undefined,
  basicDetails: BasicDetails
): SaveBasicDetailsRequest => {
  return {
    highLevelDetails: {
      id: workId ? parseInt(workId) : undefined,
      description: basicDetails.description,
      startDate: basicDetails.startDate,
      endDate: basicDetails.endDate,
    },
    highLevelDetailsTemplateId: basicDetails.templateId,
  };
};

export const useBasicDetails = () => {
  const basicDetails = useSelector(selectBasicDetails);
  const { closeEntry, isRestored } = useFormControl();
  const { save, isSaving } = useSave<SaveBasicDetailsRequest, SaveBasicDetailsResponse>();
  const navigate = useNavigate();
  const { mode, workId } = useParams();
  const dispatch = useDispatch();
  const { canSaveOrEdit } = useStatus(workId ?? "0");

  const saveUrl = workId ? `/highleveldetails/${workId}` : "/highleveldetails";
  const saveMethod = workId ? HttpMethod.PUT : HttpMethod.POST;

  const back = () => {
    closeEntry();
  };

  const saveAndClose = async () => {
    const { success } = await save(
      createSavePayload(workId, basicDetails.form),
      saveUrl,
      saveMethod
    );

    if (success) {
      closeEntry();
    }
  };

  const next = async () => {
    if (basicDetails.form.startDate === "" || basicDetails.form.endDate === "") {
      toast.error("Must provide a start and end date");
      return;
    }

    if (workId) {
      navigate(
        `/${basicDetails.form.templateId !== 0 ? EntryMode.Edit : mode}/${workId}/energy-usage`
      );
      return;
    }

    const nextPage = () =>
      navigate(
        `/${basicDetails.form.templateId !== 0 ? EntryMode.Edit : mode}/${
          response?.id ?? workId
        }/energy-usage`
      );

    if (!canSaveOrEdit) {
      nextPage();
      return;
    }

    const { success, response } = await save(
      createSavePayload(workId, basicDetails.form),
      saveUrl,
      saveMethod
    );

    if (success) {
      dispatch(setStatus(FormStatus.Complete));
      nextPage();
    }
  };

  const submit = () => {
    navigate("/results");
  };

  return {
    saveAndClose,
    back,
    next,
    submit,
    isSaving,
    isRestored,
    mode,
    workId,
    canSaveOrEdit,
  };
};
