import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export type ImportDetails = {
  description: string;
  startDate: string;
  endDate: string;
  csvFile: File | null;
};

export interface ImportDetailsState {
  importDetails: ImportDetails;
}

const initialState: ImportDetailsState = {
  importDetails: {
    description: "",
    startDate: "",
    endDate: "",
    csvFile: null,
  },
};

const importDetailsReducer = {
  setDescription: (state: ImportDetailsState, action: PayloadAction<string>) => {
    state.importDetails.description = action.payload;
  },
  setStartDate: (state: ImportDetailsState, action: PayloadAction<string>) => {
    state.importDetails.startDate = action.payload;
  },
  setEndDate: (state: ImportDetailsState, action: PayloadAction<string>) => {
    state.importDetails.endDate = action.payload;
  },
  setCsvFile: (state: ImportDetailsState, action: PayloadAction<File | null>) => {
    state.importDetails.csvFile = action.payload;
  },
};

export const importDetailsSlice = createSlice({
  name: "importDetails",
  initialState: initialState,
  reducers: importDetailsReducer,
});

export const { setDescription, setStartDate, setEndDate, setCsvFile } = importDetailsSlice.actions;

export const selectImportDetails = (state: RootState) => state.importDetails;
