import { useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";

export type emissionsCategory = {
  id: number;
  name: string;
};
export enum EmissionsCategoryId {
  Asset = 1,
  Waste = 2,
  Material = 3,
  Energy = 4,
}

export type EmissionsCategory = {
  id: EmissionsCategoryId;
  name: string;
};
export const useEmissionsCategory = () => {
  const fetchEmissionsCategories = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/emissionsCategory`;
    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });
    const emissionsCategories: emissionsCategory[] = await response.json();

    return emissionsCategories;
  };

  const { data, isLoading } = useQuery(`categories`, fetchEmissionsCategories);

  return { values: data || [], isLoading };
};
