import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export type OrganisationToCreate = {
  name: string;
  utilitySectorID: number;
  isContractor: boolean;
};

interface OrganisationState {
  organisation: OrganisationToCreate;
}

const initialState: OrganisationState = {
  organisation: {
    name: "",
    utilitySectorID: 1,
    isContractor: false,
  },
};

export const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {
    setOrganisationName: (state, action: PayloadAction<string>) => {
      state.organisation.name = action.payload;
    },
    setOrganisationUtilitySector: (state, action: PayloadAction<number>) => {
      state.organisation.utilitySectorID = action.payload;
    },
    setIsContractor: (state, action: PayloadAction<boolean>) => {
      state.organisation.isContractor = action.payload;
    },
    resetOrganisationFormState: (state) => {
      state.organisation = initialState.organisation;
    },
  },
});

export const {
  setOrganisationName,
  setOrganisationUtilitySector,
  resetOrganisationFormState,
  setIsContractor,
} = organisationSlice.actions;

export const organisationForm = (state: RootState) => state.organisationForm;
