import Header from "../Header/Header";
import "./MaterialHeaders.scss";

const MaterialHeaders = () => {
  return (
    <div className="material-headers">
      <Header label="Material type" />
      <Header label="Material" />
      <Header label="Quantity" />
      <Header label="Units" />
      <Header label="Percentage obtained from recycled sources" />
      <Header label="Scope" />
    </div>
  );
};

export default MaterialHeaders;
