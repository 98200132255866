import { useNavigate, useParams } from "react-router-dom";
import {
  EnergyConsumer,
  selectEnergyUsage,
  setStatus,
} from "../features/energyUsage/energyUsageSlice";
import { useFormControl } from "./useFormControl";
import { useSave } from "./useSave";
import { useCalculate } from "./useCalculate";
import { FormStatus } from "../models/formStatus";
import { useDispatch, useSelector } from "react-redux";
import { useStatus } from "./useStatus";
import { EmissionsCategoryId } from "./useEmissionsCategory";

type EmissionsEntryDto = {
  highLevelDetailsId: number;
  emissionsSourceId: number;
  quantity: number;
  unitOfMeasureId: number;
  percentageRecycled: number | undefined;
  scopeId: number;
};

type SaveEmissionsEntryRequest = {
  emissionsEntries: EmissionsEntryDto[];
};

const createSavePayload = (
  energyConsumers: EnergyConsumer[],
  workId: string
): SaveEmissionsEntryRequest => {
  return {
    emissionsEntries: energyConsumers.map((e) => ({
      highLevelDetailsId: parseInt(workId),
      emissionsSourceId: e.purposeId || 0,
      quantity: parseInt(e.quantity || ""),
      unitOfMeasureId: e.unitOfMeasureId || 0,
      percentageRecycled: 0,
      scopeId: parseInt(e.scopeId || ""),
    })),
  };
};

export const useEnergyEmissionsEntry = () => {
  const energyUsage = useSelector(selectEnergyUsage);
  const { closeEntry } = useFormControl();
  const { save, isSaving } = useSave<SaveEmissionsEntryRequest>();
  const navigate = useNavigate();
  const { mode, workId } = useParams();
  const { calculateResults } = useCalculate();
  const dispatch = useDispatch();
  const { canSaveOrEdit } = useStatus(workId ?? "");

  const back = () => {
    navigate(`/${mode}/${workId}/basic-details`);
  };

  const saveAndClose = async () => {
    if (!workId || !canSaveOrEdit) return;

    const { success } = await save(
      createSavePayload(energyUsage.form, workId),
      `/emissionsEntry/save/emissionscategory/${EmissionsCategoryId.Energy}`
    );

    await calculateResults(workId);

    if (success) {
      closeEntry();
    }
  };

  const next = async () => {
    if (!workId) return;

    if (!canSaveOrEdit) {
      navigate(`/${mode}/${workId}/material-usage`);
      return;
    }

    const { success } = await save(
      createSavePayload(energyUsage.form, workId),
      `/emissionsEntry/save/emissionscategory/${EmissionsCategoryId.Energy}`
    );

    if (success) {
      navigate(`/${mode}/${workId}/material-usage`);
      dispatch(setStatus(FormStatus.Complete));
    }
  };

  return {
    back,
    saveAndClose,
    next,
    isSaving,
    canSaveOrEdit,
  };
};
