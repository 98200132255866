import { resetBasicDetails } from "../features/basicDetails/basicDetailsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useLoad } from "./useLoad";
import { EntryMode } from "../models/entryMode";
import { resetFormControl, selectFormControl } from "../features/formControl/formControlSlice";
import { resetAssetUsage } from "../features/assetUsage/assetUsageSlice";
import { resetEnergyUsage } from "../features/energyUsage/energyUsageSlice";
import { resetWaste } from "../features/waste/wasteSlice";
import { resetMaterialUsage } from "../features/materialUsage/materialUsageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "./useAuth";

export const useFormControl = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isRestored, isRestoring, errorRestoring } = useSelector(selectFormControl);
  const { restoreForm } = useLoad();
  const { mode, workId } = useParams();
  const isContractor = useAuth().isContractor;

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
  }, []);

  useEffect(() => {
    if (
      (mode === EntryMode.Edit || mode === EntryMode.Review) &&
      workId &&
      !isRestored &&
      !isRestoring &&
      !errorRestoring
    ) {
      restoreForm(workId);
    }
  }, [mode, workId, isRestored, isRestoring, restoreForm, errorRestoring]);

  const closeEntry = () => {
    dispatch(resetBasicDetails());
    dispatch(resetAssetUsage());
    dispatch(resetEnergyUsage());
    dispatch(resetMaterialUsage());
    dispatch(resetWaste());
    dispatch(resetFormControl());

    window.onbeforeunload = null;
    isContractor ? navigate("/contractor-dashboard") : navigate("/entry-dashboard");
  };

  return {
    closeEntry,
    isRestored,
  };
};
