import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export type PairedOrganisationToCreate = {
  parentId: number;
  childId: number;
};

interface OrganisationState {
  pair: PairedOrganisationToCreate;
}

const initialState: OrganisationState = {
  pair: {
    parentId: 0,
    childId: 0,
  },
};

export const pairedOrganisationSlice = createSlice({
  name: "paired-organisation",
  initialState,
  reducers: {
    setParentId: (state, action: PayloadAction<number>) => {
      state.pair.parentId = action.payload;
    },
    setChildId: (state, action: PayloadAction<number>) => {
      state.pair.childId = action.payload;
    },
  },
});

export const { setParentId, setChildId } = pairedOrganisationSlice.actions;

export const pairedOrganisationForm = (state: RootState) => state.pairedOrganisationForm;
