import { Card } from "../../components/Card/Card";
import { Select } from "../../components/FormComponents";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import Table, { RowData } from "../../components/Table/Table";
import { Lookup } from "../../models/lookup";
import "./Admin.scss";
import { useDispatch, useSelector } from "react-redux";
import { resetOrganisationFormState } from "../../features/organisation/organisationSlice";
import { usePairedOrganisation } from "../../hooks/usePairedOrganisation";
import {
  pairedOrganisationForm,
  setChildId,
  setParentId,
} from "../../features/pairedOrganisationSlice/pairedOrganisationSlice";
import { useOrganisation } from "../../hooks/useOrganisation";

const PairedOrganisation = () => {
  const { pairedOrganisations, createPairedOrganisation, deletePairedOrganisation } =
    usePairedOrganisation();
  const dispatch = useDispatch();

  const { pair } = useSelector(pairedOrganisationForm);

  const { organisations } = useOrganisation();

  const contractorOrganisations = organisations
    ?.filter((org) => org.isContractor && org.organisationId !== pair.parentId.toString())
    .map((org) => ({
      id: org.organisationId,
      name: org.name,
    })) as Lookup[];

  const organisationsToRepresent = organisations
    ?.filter((org) => !org.isContractor && org.organisationId !== pair.childId.toString())
    .map((org) => ({
      id: org.organisationId,
      name: org.name,
    })) as Lookup[];

  const organisationsRowData: RowData[] = [];
  pairedOrganisations?.forEach((pair) => {
    organisationsRowData.push({
      contractor: pair.child.name,
      represents: pair.parent.name,
      actions: (
        <div className="actions">
          <PrimaryButton
            aria-label="Delete Organisation"
            label="Delete"
            onClick={() => deletePairedOrganisation(Number(pair.parent.id), Number(pair.child.id))}
          />
        </div>
      ),
    });
  });

  organisationsRowData.push({
    contractor: (
      <Select
        id="child-organisation"
        label="Contractor"
        value={pair.childId ?? "0"}
        options={contractorOrganisations}
        onChange={(e) => dispatch(setChildId(Number(e.currentTarget.value)))}
      />
    ),
    represents: (
      <Select
        id="parent-organisation"
        label="Represents"
        value={pair.parentId ?? "0"}
        options={organisationsToRepresent}
        onChange={(e) => dispatch(setParentId(Number(e.currentTarget.value)))}
      />
    ),
    actions: (
      <div className="actions">
        <PrimaryButton
          label={"Add Organisation"}
          onClick={async () => {
            if (await createPairedOrganisation(pair.parentId, pair.childId)) {
              dispatch(resetOrganisationFormState());
            }
          }}
        />
      </div>
    ),
  });

  return (
    <div className="card-layout">
      <Card title="Contractors">
        <Table
          columns={[
            {
              key: "contractor",
              header: "Contractor",
            },
            {
              key: "represents",
              header: "Represents",
            },
            {
              key: "actions",
              header: "Actions",
            },
          ]}
          data={organisationsRowData}
        />
      </Card>
    </div>
  );
};

export default PairedOrganisation;
