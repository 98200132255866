import { useMutation, useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import { Lookup } from "../models/lookup";

import toast from "react-hot-toast";

export type PairedOrganisationLookup = {
  parent: Lookup;
  child: Lookup;
};

export const usePairedOrganisation = () => {
  // Fetch Paired Parent-Child Organisations
  const fetchPairedOrganisations = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/organisation/pairedOrganisations`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    if (!response.ok) {
      throw new Error("Error fetching paired organisations");
    }

    const pairedOrganisationsResponse = (await response.json()) as PairedOrganisationLookup[];
    return pairedOrganisationsResponse;
  };

  const {
    data: pairedOrganisations,
    isLoading: isPairedOrganisationsLoading,
    refetch: refetchPairedOrganisations,
  } = useQuery("pairedOrganisations", fetchPairedOrganisations);

  // Create Paired Parent-Child Organisation
  const createPairedOrganisationMutation = useMutation(
    async ({ parentId, childId }: { parentId: number; childId: number }) => {
      const url = `${env.REACT_APP_API_BASE_URL}/organisation/pairedOrganisations/${parentId}/${childId}`;
      const response = await fetch(url, {
        method: "POST",
        headers: { Authorization: getAuthHeader() },
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`Paired organisation created`);
          refetchPairedOrganisations();
        } else {
          toast.error("Paired organisation not created");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const createPairedOrganisation = async (parentId: number, childId: number) =>
    await createPairedOrganisationMutation.mutateAsync({ parentId, childId });

  // Delete Paired Parent-Child Organisation
  const deletePairedOrganisationMutation = useMutation(
    async ({ parentId, childId }: { parentId: number; childId: number }) => {
      const url = `${env.REACT_APP_API_BASE_URL}/organisation/pairedOrganisations/${parentId}/${childId}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: { Authorization: getAuthHeader() },
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`Paired organisation deleted`);
          refetchPairedOrganisations();
        } else {
          toast.error("Paired organisation not deleted");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const deletePairedOrganisation = async (parentId: number, childId: number) => {
    if (window.confirm("Are you sure you want to delete?"))
      await deletePairedOrganisationMutation.mutateAsync({ parentId, childId });
  };

  return {
    pairedOrganisations,
    isPairedOrganisationsLoading,
    createPairedOrganisation,
    deletePairedOrganisation,
  };
};
