import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import "./FormButtons.scss";

interface FormButtonsProps {
  onBack?: () => void;
  onSaveAndClose?: () => void;
  onNext?: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  submitLabel?: string;
}

export const FormButtons = ({
  onBack,
  onSaveAndClose,
  onNext,
  onSubmit,
  disabled,
  submitLabel,
}: FormButtonsProps) => {
  return (
    <div className="form-buttons">
      <PrimaryButton label="Back" onClick={onBack} disabled={disabled} />
      {onSaveAndClose && (
        <PrimaryButton label="Save and Close" onClick={onSaveAndClose} disabled={disabled} />
      )}
      {onSubmit ? (
        <PrimaryButton
          label={submitLabel ?? "Save and View Results"}
          onClick={onSubmit}
          disabled={disabled}
        />
      ) : null}
      {onNext ? <PrimaryButton label="Next" onClick={onNext} disabled={disabled} /> : null}
    </div>
  );
};
