import { useMutation, useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import toast from "react-hot-toast";
import { OrganisationToCreate } from "../features/organisation/organisationSlice";

export type Organisation = {
  organisationId: string;
  name: string;
  email: string;
  utilitySectorID: string;
  isContractor: string;
};

export const useOrganisation = () => {
  const fetchOrganisations = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/organisation`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    if (!response.ok) {
      throw new Error("Error fetching organisations");
    }

    const organisationsResponse = (await response.json()) as Organisation[];
    return organisationsResponse;
  };

  const {
    data: organisations,
    isLoading: isOrganisationsLoading,
    refetch: refetchOrganisations,
  } = useQuery("organisations", fetchOrganisations);

  const createOrganisationMutation = useMutation(
    async (organisation: OrganisationToCreate) => {
      const url = `${env.REACT_APP_API_BASE_URL}/organisation`;
      const response = await fetch(url, {
        method: "POST",
        headers: { Authorization: getAuthHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ organisation }),
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`Organisation created`);
          refetchOrganisations();
        } else {
          toast.error("Organisation not created");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const createOrganisation = async (organisation: OrganisationToCreate) =>
    await createOrganisationMutation.mutateAsync(organisation);

  const deleteOrganisationMutation = useMutation(
    async (id: string) => {
      const url = `${env.REACT_APP_API_BASE_URL}/organisation/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: { Authorization: getAuthHeader() },
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`Organisation deleted`);
          refetchOrganisations();
        } else {
          toast.error("Organisation not deleted");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const deleteOrganisation = async (id: string) => {
    if (window.confirm("Are you sure you want to delete?"))
      await deleteOrganisationMutation.mutateAsync(id);
  };

  return {
    organisations,
    isOrganisationsLoading,
    createOrganisation,
    deleteOrganisation,
  };
};
