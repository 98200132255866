import { useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import toast from "react-hot-toast";
import { useFormControl } from "./useFormControl";

export enum StatusName {
  DRAFT = "DRAFT",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
}

interface Status {
  id: number;
  name: StatusName;
}

enum Action {
  SUBMIT = "submit",
  REJECT = "reject",
  RECALL = "recall",
}

export const useStatus = (highLevelDetailsId: string) => {
  const { closeEntry } = useFormControl();

  const fetchStatus = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/status/${highLevelDetailsId}`;

    const response = await fetch(url, {
      method: "GET", // Specify the HTTP method
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    const statusResponse = (await response.json()) as Status;

    return statusResponse;
  };

  const { data: status, isFetching, refetch: refetchStatus } = useQuery("status", fetchStatus);

  const updateStatus = async (action: Action) => {
    const url = `${env.REACT_APP_API_BASE_URL}/status/${action}/${highLevelDetailsId}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    if (!response.ok) {
      toast.error("Failed to update status");
      return;
    }

    toast.success("Submitted");
    closeEntry();
  };

  const canSaveOrEdit = status?.name === StatusName.DRAFT ?? false;

  const isAwaitingApproval = status?.name === StatusName.AWAITING_APPROVAL;

  const isInDraft = status?.name === StatusName.DRAFT;

  const canBeRecalled =
    status?.name === StatusName.AWAITING_APPROVAL ||
    status?.name === StatusName.APPROVED ||
    status?.name === StatusName.REJECTED;

  const submit = () => updateStatus(Action.SUBMIT);
  const reject = () => updateStatus(Action.REJECT);
  const recall = () => (canBeRecalled ? updateStatus(Action.RECALL) : null);

  return {
    status,
    canSaveOrEdit,
    isAwaitingApproval,
    isFetching,
    submit,
    reject,
    recall,
    isInDraft,
    canBeRecalled,
  };
};
