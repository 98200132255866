import { ReactNode } from "react";
import "./Table.scss";

interface Column {
  key: string;
  header: string;
}

export interface RowData {
  [key: string]: ReactNode;
}

interface TableProps {
  columns: Column[];
  data: RowData[];
  title?: string;
}

const Table = ({ columns, data, title }: TableProps) => {
  return (
    <div className="table-container">
      {title && <h2 className="table-container__title">{title}</h2>}
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column) => (
                <td key={column.key}>{row[column.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
