import { Card } from "../../components/Card/Card";
import { Checkbox, Select, TextInput } from "../../components/FormComponents";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import Table, { RowData } from "../../components/Table/Table";
import { useLookup } from "../../hooks/useLookup";
import { useOrganisation } from "../../hooks/useOrganisation";
import { LookupKey } from "../../models/lookup";
import "./Admin.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  organisationForm,
  resetOrganisationFormState,
  setIsContractor,
  setOrganisationName,
  setOrganisationUtilitySector,
} from "../../features/organisation/organisationSlice";

const Organisation = () => {
  const { organisations, createOrganisation, deleteOrganisation } = useOrganisation();
  const { values: utilitySectors } = useLookup(LookupKey.UtilitySectors);
  const dispatch = useDispatch();

  const { organisation } = useSelector(organisationForm);

  const organisationsRowData: RowData[] = [];
  organisations?.forEach((organisation) => {
    organisationsRowData.push({
      ...organisation,
      isContractor: organisation.isContractor.toString(),
      sector: utilitySectors.find((u) => u.id === organisation.utilitySectorID)?.name,
      actions: (
        <div className="actions">
          <PrimaryButton
            aria-label="Delete Organisation"
            label="Delete"
            onClick={() => deleteOrganisation(organisation.organisationId)}
          />
        </div>
      ),
    });
  });

  organisationsRowData.push({
    name: (
      <TextInput
        id={"organisation-name"}
        label={"Name"}
        value={organisation.name}
        onChange={(e) => dispatch(setOrganisationName(e.currentTarget.value))}
      ></TextInput>
    ),
    sector: (
      <Select
        id="sectorId"
        label="Sector"
        value={""}
        options={utilitySectors}
        onChange={(e) => dispatch(setOrganisationUtilitySector(Number(e.currentTarget.value)))}
      />
    ),
    isContractor: (
      <Checkbox
        id={"is-contractor"}
        checked={organisation.isContractor}
        onChange={() => dispatch(setIsContractor(!organisation.isContractor))}
      ></Checkbox>
    ),
    actions: (
      <div className="actions">
        <PrimaryButton
          label={"Add Organisation"}
          onClick={async () => {
            if (await createOrganisation(organisation)) {
              dispatch(resetOrganisationFormState());
            }
          }}
        />
      </div>
    ),
  });

  return (
    <div className="card-layout">
      <Card title="organisations">
        <Table
          columns={[
            {
              key: "name",
              header: "Name",
            },
            {
              key: "sector",
              header: "Sector",
            },
            {
              key: "isContractor",
              header: "Is Contractor",
            },
            {
              key: "actions",
              header: "Actions",
            },
          ]}
          data={organisationsRowData}
        />
      </Card>
    </div>
  );
};

export default Organisation;
