import { useQuery } from "react-query";
import { env } from "../env";
import { ResultDashboardData } from "../models/resultDashboardData";
import { getAuthHeader } from "../utils/tokenUtils";
import { useNavigate, useParams } from "react-router-dom";
import { useFormControl } from "./useFormControl";
import { useStatus } from "./useStatus";
import { EntryMode } from "../models/entryMode";

export type ResultDashboardResponse = {
  data: ResultDashboardData;
  success: boolean;
  message: string | null;
};

export const useResults = () => {
  const { mode, workId } = useParams();
  const navigate = useNavigate();
  const { closeEntry } = useFormControl();
  const { isAwaitingApproval, isInDraft, canBeRecalled, submit, reject, recall } = useStatus(
    workId ?? ""
  );

  const back = () => {
    navigate(`/${mode === EntryMode.New ? EntryMode.Edit : mode}/${workId}/waste`);
  };

  const close = () => {
    closeEntry();
    navigate(`/entry-dashboard`);
  };

  const fetchResults = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/emissions/${workId}`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    const resultDashboardResponse = (await response.json()) as ResultDashboardData;
    return resultDashboardResponse;
  };

  const { data, isLoading } = useQuery("resultsdashboard", fetchResults);

  return {
    data,
    isLoading,
    back,
    close,
    isAwaitingApproval,
    isInDraft,
    submit,
    reject,
    recall,
    canBeRecalled,
  };
};
