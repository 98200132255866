import "./EnergyConsumerRow.scss";
import { Select, NumberInput } from "../../FormComponents";
import { DeleteButton } from "../../DeleteButton/DeleteButton";
import { EnergyConsumer } from "../../../features/energyUsage/energyUsageSlice";
import { Category } from "../../../utils/consts";
import { useEmissionsSourceLookup } from "../../../hooks/useEmissionsSource";
import { useEmissionsTypeLookup } from "../../../hooks/useEmissionsType";
import { useScope } from "../../../hooks/useScope";
import { useUnitOfMeasureLookup } from "../../../hooks/useUnitOfMeasure";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";

interface EnergyConsumerRowProps {
  energyConsumer: EnergyConsumer;
  onRemove: (energyConsumerId: number | null) => void;
  onChange: (a: EnergyConsumer) => void;
  disabled?: boolean;
}

export const EnergyConsumerRow = ({
  energyConsumer,
  onRemove,
  onChange,
  disabled,
}: EnergyConsumerRowProps) => {
  const { id, energyTypeId, purposeId, quantity, unitOfMeasureId, scopeId } = energyConsumer;
  const { values: energyTypes, isLoading: isEnergyTypesLoading } = useEmissionsTypeLookup(
    Category.ENERGY
  );
  const { values: purposes, isLoading: isPurposesLoading } = useEmissionsSourceLookup(
    Category.ENERGY,
    energyTypeId ? energyTypeId : 0
  );
  const { values: scopes } = useScope();
  const { values: units, isLoading: isUnitsLoading } = useUnitOfMeasureLookup(purposeId ?? 0);

  const handleEnergyTypeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...energyConsumer, energyTypeId: Number(e.currentTarget.value), purposeId: null });
  };

  const handlePurposeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({
      ...energyConsumer,
      purposeId: Number(e.currentTarget.value),
    });
  };

  const handleQuantityChange = (quantity: string) => {
    return onChange({ ...energyConsumer, quantity: quantity });
  };

  const handleUnitofMeasureChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...energyConsumer, unitOfMeasureId: Number(e.currentTarget.value) });
  };

  const handleScopeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...energyConsumer, scopeId: e.currentTarget.value });
  };

  return (
    <div className="energy-consumer-row">
      <Select
        id={`energy-type-${id}`}
        label="Energy Type"
        options={energyTypes}
        value={energyTypeId}
        onChange={handleEnergyTypeChange}
        disabled={disabled}
        isLoading={isEnergyTypesLoading}
      />

      <Select
        id={`purpose-${id}`}
        label="Purpose"
        options={purposes}
        value={purposeId}
        onChange={handlePurposeChange}
        disabled={disabled}
        isLoading={isPurposesLoading}
      />
      <NumberInput
        id={`quantity-${id}`}
        label="Quantity"
        value={quantity}
        min={0}
        onChange={handleQuantityChange}
        disabled={disabled}
      />
      <Select
        id={`units-${id}`}
        label="Units"
        options={units}
        value={unitOfMeasureId}
        onChange={handleUnitofMeasureChange}
        disabled={disabled}
        isLoading={isUnitsLoading}
      />
      <Select
        id={`scope-${id}`}
        label="Scope"
        options={scopes}
        value={scopeId}
        onChange={handleScopeChange}
        disabled={disabled}
      />

      <DeleteButton onClick={() => onRemove(id)} disabled={disabled} />
    </div>
  );
};
