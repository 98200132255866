import { useQuery } from "react-query";
import { env } from "../env";
import { ResultDashboardData } from "../models/resultDashboardData";
import { getAuthHeader } from "../utils/tokenUtils";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { selectOrganisationResultForm } from "../features/organisationResultsSlice/organisationResultsSlice";

export const useOrganisationResults = () => {
  const sectorResultFormState = useSelector(selectOrganisationResultForm);

  const { startDate, endDate } = sectorResultFormState;

  const fetchResults = async () => {
    if (startDate.length === 0 && endDate.length === 0) return;
    const url = `${env.REACT_APP_API_BASE_URL}/emissions/myOrganisation`;

    const response = await fetch(url, {
      method: "POST", // Specify the HTTP method
      headers: {
        Authorization: getAuthHeader(),
        "Content-Type": "application/json", // Specify the content type
      },
      body: JSON.stringify({
        startDate: startDate,
        endDate: endDate,
      }),
    });

    const resultDashboardResponse = (await response.json()) as ResultDashboardData;
    return resultDashboardResponse;
  };

  const { data, isFetching, refetch } = useQuery("resultsdashboard", fetchResults);

  useEffect(() => {
    if (startDate.length > 0 && endDate.length > 0) {
      refetch();
    }
  }, [startDate, endDate]);

  return {
    data,
    isFetching,
  };
};
