import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import toast from "react-hot-toast";

export const useExportCsv = () => {
  const exportEntry = async (highLevelDetailsId: string) => {
    try {
      const url = `${env.REACT_APP_API_BASE_URL}/export/worksEntry/${highLevelDetailsId}`;
      const response = await fetch(url, {
        headers: { Authorization: getAuthHeader() },
      });

      if (!response.ok) {
        toast.error("Export Failed");
        return;
      }

      toast.success("Export Downloading");

      const csvBlob = await response.blob();
      const urlBlob = window.URL.createObjectURL(csvBlob);
      const link = document.createElement("a");
      link.href = urlBlob;
      link.setAttribute("download", `emissions_entries_${highLevelDetailsId}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      toast.error("Export Failed");
    }
  };

  return { exportEntry };
};
