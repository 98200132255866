import { FieldGroup } from "../../components/FieldGroup/FieldGroup";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { TextInput } from "../../components/FormComponents";
import { InputRow } from "../../components/InputRow/InputRow";
import { useDispatch, useSelector } from "react-redux";
import { DateRange } from "../../components/FormComponents/Date/Date";
import {
  selectImportDetails,
  setCsvFile,
  setDescription,
  setEndDate,
  setStartDate,
} from "../../features/importDetails/importDetailsSlice";
import { useImportDetails } from "../../hooks/useImportDetails";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";

export const ImportNewWorks = () => {
  const { importDetails } = useSelector(selectImportDetails);
  const { submit } = useImportDetails();
  const dispatch = useDispatch();

  const infoPaneText = (
    <>
      File must be set up with the following headers:
      EmissionsSourceId,UnitOfMeasureId,ScopeId,Quantity,PercentageRecycled
    </>
  );

  return (
    <PageContainer heading="Import" informationalPaneBodyText={infoPaneText} displayNavigationPane>
      <FieldGroup>
        <h2>Date range and description</h2>
        <DateRange
          id={"new-works-date-range"}
          onChange={({ startDate, endDate }) => {
            dispatch(setStartDate(startDate));
            dispatch(setEndDate(endDate));
          }}
          dateFieldWrapper={InputRow}
          startDateValue={importDetails.startDate}
          endDateValue={importDetails.endDate}
        />
        <InputRow inputId="description" label="Description">
          <TextInput
            id="description"
            label="Description"
            value={importDetails.description}
            onChange={(e) => dispatch(setDescription(e.currentTarget.value))}
          />
        </InputRow>
        <InputRow label={"CSV File"} inputId={"csv"}>
          <input
            type="file"
            accept=".csv"
            onChange={(event) =>
              dispatch(setCsvFile(event.target.files ? event.target.files[0] : null))
            }
          />
        </InputRow>
      </FieldGroup>
      <PrimaryButton label="Save and View Results" onClick={submit} />
    </PageContainer>
  );
};
