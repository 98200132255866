import { useParams } from "react-router-dom";
import { useResults } from "../../hooks/useResults";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import { SideNav } from "../SideNav/SideNav";
import "./ResultsPageContainer.scss";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import { useAuth } from "../../hooks/useAuth";

interface ResultsPageContainerProps {
  children?: React.ReactNode;
  heading: React.ReactNode;
  actions?: React.ReactNode;
}

export const ResultsPageContainer = ({ children, heading }: ResultsPageContainerProps) => {
  const { back, close, submit, reject, recall, isAwaitingApproval, isInDraft, canBeRecalled } =
    useResults();
  const { mode } = useParams();

  const { isContractor } = useAuth();

  return (
    <div className="results-page-layout">
      <SideNav />
      <div className="content-container">
        <div className="content-container__top-bar">
          <h1 className="content-container__top-bar__heading">{heading}</h1>
        </div>
        <div className="content-container__content">{children}</div>
        <div className="content-container__bottom-bar">
          <PrimaryButton label={"Back"} onClick={() => back()} />
          <PrimaryButton label={"Close"} onClick={() => close()} />
          {isInDraft && (
            <PrimaryButton
              label={isContractor ? "Submit for Review" : "Complete"}
              onClick={() => submit()}
            />
          )}
          {isAwaitingApproval && !isContractor && mode === "review" && (
            <>
              <PrimaryButton label={"Approve"} onClick={() => submit()} />
              <PrimaryButton label={"Reject"} onClick={() => reject()} />
            </>
          )}
          {canBeRecalled && (
            <>
              <PrimaryButton
                label={"Recall"}
                onClick={() => {
                  recall();
                  close();
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
