import React, { ReactNode } from "react";
import Table, { RowData } from "../../components/Table/Table";
import { formatDate } from "../../utils/dateUtils";
import { useExportCsv } from "../../hooks/useExportCsv";
import { HighLevelDetail } from "../../models/highLevelDetail";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";

interface EntriesTableProps {
  highLevelDetails: HighLevelDetail[] | undefined;
  deleteHighLevelDetail?: (id: string) => void;
  title?: string;
  actions?: (highLevelDetail: HighLevelDetail) => ReactNode;
}

export const EntriesTable: React.FC<EntriesTableProps> = ({
  highLevelDetails,
  deleteHighLevelDetail,
  title,
  actions,
}) => {
  const { exportEntry } = useExportCsv();

  const tableData: RowData[] = [];
  highLevelDetails?.forEach((highLevelDetail) => {
    tableData.push({
      status: highLevelDetail.status.name,
      startDate: formatDate(highLevelDetail.startDate, "DD-MM-YYYY"),
      endDate: formatDate(highLevelDetail.endDate, "DD-MM-YYYY"),
      emissions: highLevelDetail.emissions,
      description: highLevelDetail.description,
      createdBy: highLevelDetail.createdByOrganisation.name,
      actions: (
        <div className="actions">
          <Link
            aria-label="View Results"
            className="actions__link"
            to={`/edit/${highLevelDetail.id}/results`}
          >
            View Results
          </Link>
          {actions && actions(highLevelDetail)}
          <PrimaryButton
            aria-label="Export Entry"
            label="Export"
            onClick={() => exportEntry(highLevelDetail.id)}
          />
          {deleteHighLevelDetail && (
            <PrimaryButton
              aria-label="Delete Entry"
              label="Delete"
              onClick={() => deleteHighLevelDetail(highLevelDetail.id)}
            />
          )}
        </div>
      ),
    });
  });

  return (
    <Table
      columns={[
        {
          key: "status",
          header: "Status",
        },
        {
          key: "startDate",
          header: "Start Date",
        },
        {
          key: "endDate",
          header: "End Date",
        },
        {
          key: "emissions",
          header: "Emissions",
        },
        {
          key: "description",
          header: "Description",
        },
        {
          key: "createdBy",
          header: "Created By",
        },
        {
          key: "actions",
          header: "",
        },
      ]}
      data={tableData}
      title={title}
    />
  );
};
