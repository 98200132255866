import { PageContainer } from "../../components/PageContainer/PageContainer";
import Table, { RowData } from "../../components/Table/Table";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import "./ContractorDashboard.scss";
import { useHighLevelDetails } from "../../hooks/useHighLevelDetails";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils";
import { useActiveOrganisation } from "../../hooks/useActiveOrganisation";
import { EntriesTable } from "../../components/EntriesTable/EntriesTable";
import { StatusName } from "../../hooks/useStatus";

export const ContractorDashboard = () => {
  const {
    activeOrganisationData: highLevelDetails,
    isLoading,
    deleteHighLevelDetail,
    actionableHighLevelDetails,
    isLoadingActionableHighLevelDetails,
  } = useHighLevelDetails();
  const { activeOrganisation } = useActiveOrganisation();

  const approvedHighLevelDetails = highLevelDetails?.filter(
    (detail) => detail.status.name === StatusName.APPROVED
  );

  const draftHighLevelDetails = highLevelDetails?.filter(
    (detail) => detail.status.name === StatusName.DRAFT
  );

  const tableData: RowData[] = [];
  highLevelDetails?.forEach((highLevelDetail) => {
    tableData.push({
      startDate: formatDate(highLevelDetail.startDate, "DD-MM-YYYY"),
      endDate: formatDate(highLevelDetail.endDate, "DD-MM-YYYY"),
      emissions: highLevelDetail.emissions,
      description: highLevelDetail.description,
      actions: (
        <div className="actions">
          <Link
            aria-label="View Results"
            className="actions__link"
            to={`/edit/${highLevelDetail.id}/results`}
          >
            View Results
          </Link>
          <Link
            aria-label="Edit Entry"
            className="actions__link"
            to={`/edit/${highLevelDetail.id}/basic-details`}
          >
            Edit
          </Link>
        </div>
      ),
    });
  });

  return (
    <PageContainer
      heading={`${activeOrganisation?.name} Entries`}
      informationalPaneBodyText="On this page, you can find all your company’s entries on the carbon calculator."
    >
      {isLoadingActionableHighLevelDetails ? (
        <div>It's loading!</div>
      ) : (
        <EntriesTable
          title="Pending Contractor Entries"
          highLevelDetails={actionableHighLevelDetails}
          deleteHighLevelDetail={deleteHighLevelDetail}
        />
      )}
      {isLoading ? (
        <div>It's loading!</div>
      ) : (
        <>
          <EntriesTable
            title="My Draft entries"
            highLevelDetails={draftHighLevelDetails}
            deleteHighLevelDetail={deleteHighLevelDetail}
            actions={(highLevelDetail) => (
              <Link
                aria-label="Edit Entry"
                className="actions__link"
                to={`/edit/${highLevelDetail.id}/basic-details`}
              >
                Edit
              </Link>
            )}
          />
          <EntriesTable title="All Approved Entries" highLevelDetails={approvedHighLevelDetails} />
        </>
      )}
    </PageContainer>
  );
};
