import { useSelector } from "react-redux";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import { selectImportDetails } from "../features/importDetails/importDetailsSlice";
import toast from "react-hot-toast";

export const useImportDetails = () => {
  const { importDetails } = useSelector(selectImportDetails);

  const submit = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/import/newworks`;

    const formData = new FormData();
    formData.append("description", importDetails.description);
    formData.append("startDate", importDetails.startDate);
    formData.append("endDate", importDetails.endDate);

    if (importDetails.csvFile) {
      formData.append("file", importDetails.csvFile);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { Authorization: getAuthHeader() },
        body: formData,
      });

      return response.ok;
    } catch (e) {
      toast.error("Import failed");
    }
  };

  return {
    submit,
  };
};
