import { Dayjs } from "dayjs";
import { Lookup } from "./lookup";

type Status = {
  id: number;
  name: string;
};

export type HighLevelDetail = {
  id: string;
  startDate: Dayjs;
  endDate: Dayjs;
  emissions: string;
  description: string;
  organisation: Lookup;
  createdByOrganisation: Lookup;
  status: Status;
};

type HighLevelDetailDTO = {
  id: number;
  startDate: Dayjs;
  endDate: Dayjs;
  description: string;
  organisationID: number;
  totalEmissions: string | null;
  organisation: Lookup;
  createdByOrganisation: Lookup;
  status: Status;
};

export const highLevelDetailsMapper = (jsonArray: HighLevelDetailDTO[]): HighLevelDetail[] => {
  return jsonArray.map((jsonObject) => ({
    id: jsonObject.id.toString(),
    startDate: jsonObject.startDate,
    endDate: jsonObject.endDate,
    emissions: jsonObject.totalEmissions || "-",
    description: jsonObject.description,
    organisation: jsonObject.organisation,
    createdByOrganisation: jsonObject.createdByOrganisation,
    status: jsonObject.status,
  }));
};
