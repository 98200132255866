import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken, parseJwt } from "../utils/tokenUtils";
import dayjs from "dayjs";
import { useAuth } from "./useAuth";
import { useDispatch } from "react-redux";
import { setActiveOrganisationId, setRole } from "../features/user/userSlice";

export const useProtectedRoute = (allowedRoles: string[]) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logout } = useAuth();

  useEffect(() => {
    const jwt = getToken();

    if (!jwt) {
      navigate(`/login?redirect=${pathname}`);
      return;
    }

    const { exp, role, activeOrganisationId } = parseJwt(jwt);
    const isExpired = dayjs(exp * 1000).isBefore(dayjs());

    if (isExpired) {
      logout();
      return;
    }

    dispatch(setRole(role));
    dispatch(setActiveOrganisationId(activeOrganisationId));

    if (!allowedRoles.includes(role)) {
      navigate(`/contact-administrator`);
    }
  }, [navigate, pathname, dispatch, logout]);
};
