import { useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import { Lookup } from "../models/lookup";
import { Organisation } from "./useOrganisation";

export const useActiveOrganisation = () => {
  const fetchAvailableActiveOrganisations = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/organisation/availableToSelect`;
    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    if (!response.ok) {
      throw new Error("Error fetching available organisations");
    }

    const organisationsResponse = (await response.json()) as Lookup[];
    return organisationsResponse;
  };

  const fetchActiveOrganisation = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/organisation/active`;
    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    if (!response.ok) {
      throw new Error("Error fetching active organisation");
    }

    const activeOrganisationResponse = (await response.json()) as Organisation;
    return activeOrganisationResponse;
  };

  const { data: availableOrganisations, isLoading: isAvailableOrganisationsLoading } = useQuery(
    "available-organisations",
    fetchAvailableActiveOrganisations
  );

  const { data: activeOrganisation, isLoading: isActiveOrganisationLoading } = useQuery(
    "active-organisation",
    fetchActiveOrganisation
  );

  return {
    availableOrganisations,
    isAvailableOrganisationsLoading,
    activeOrganisation,
    isActiveOrganisationLoading,
  };
};
